import axios from 'axios';
import { geographicalCoordinatesUrl } from '../constants/config';

export const getGeographicalCoordinates = async (
  country,
  postalCode,
  city,
  region
) => {
  try {
    const response = await axios.get(
      geographicalCoordinatesUrl(country, postalCode, city, region)
    );
    if (response.data !== null) {
      return { tag: 'Success', value: response.data };
    } else {
      return { tag: 'Error', value: 'Not found' };
    }
  } catch (error) {
    return { tag: 'Error', value: error };
  }
};
